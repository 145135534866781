import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-ask-confirmation-dialog',
    template: `
    <section>
      <h3 mat-dialog-title>{{ 'ask-confirmation-dialog.component.vuoi-eliminare-defin' | translate }}</h3>
      <div class="my-2">
        <strong>
          {{ 'ask-confirmation-dialog.component.questa-azione-non-pu' | translate }}
        </strong>
      </div>
      <!-- <p *ngIf="data.message">{{ data.message }}</p> -->
      <mat-form-field appearance="outline" class="w-100 mt-2">
        <mat-label>{{ 'ask-confirmation-dialog.component.titolo-finalita-da-e' | translate }}</mat-label>
        <input matInput name="name" [formControl]="resourceToDelete" required />
      </mat-form-field>

      <section class="d-flex justify-content-center" mat-dialog-actions>
        <button mat-flat-button mat-dialog-close>{{ 'ask-confirmation-dialog.component.annulla' | translate }}</button>

        <button
          [disabled]="!resourceToDelete.valid"
          mat-raised-button
          color="warn"
          [mat-dialog-close]="true"
          class="ms-3">
          {{ 'ask-confirmation-dialog.component.conferma' | translate }}
        </button>
      </section>
    </section>
  `,
    imports: [MatDialogTitle, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatDialogActions, MatButton, MatDialogClose, TranslateModule]
})
export class AskConfirmationDialogComponent implements OnInit {
  data = inject<{
    resource: string;
    resourceName: string;
}>(MAT_DIALOG_DATA);

  resourceToDelete!: UntypedFormControl;

  ngOnInit(): void {
    this.resourceToDelete = new UntypedFormControl('', Validators.pattern(this.data.resource));
  }
}
