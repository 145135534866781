import { Component, input, output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-file-upload-button',
    template: `
    <input #uploader type="file" hidden [accept]="acceptedFiles()" (change)="onFileChange($event)" />

    <button [disabled]="disabled()" mat-button (click)="uploader.click()">
      {{ buttonText() }} <mat-icon>file_upload</mat-icon>
    </button>
  `,
    styles: [
        `
      :host {
        display: inline-flex;
        align-items: center;
      }
    `,
    ],
    imports: [MatButton, MatIcon]
})
export class FileUploadButtonComponent {
  readonly buttonText = input<string>('Carica un file');

  readonly acceptedFiles = input<string[]>(['*']);

  readonly disabled = input<boolean>(false);

  readonly selectedFile = output<File>();

  constructor() {}

  onFileChange(data: Event) {
    const element = data.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList?.length) {
      this.selectedFile.emit(fileList[0]);
    }
  }
}
