import { Component, Input, OnDestroy, TemplateRef, inject, input, output, viewChild } from '@angular/core';
import { MatDialog, MatDialogTitle, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Subscription, tap } from 'rxjs';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-delete-button-with-confirmation',
    template: `
    <div>
      <button mat-icon-button color="warn" (click)="openConfirmationDialog()" [matTooltip]="tooltipText()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>

    <ng-template #defaultConfirmation>
      <h2 mat-dialog-title>{{ message ? message : 'Sei sicuro di voler eliminare questo campo?' }}</h2>
      <div mat-dialog-actions class="d-flex justify-content-center">
        <button mat-flat-button [mat-dialog-close]="false">
          {{ 'delete-button-with-confirmation.component.annulla' | translate }}
        </button>
        <button mat-flat-button color="primary" class="ms-2" [mat-dialog-close]="true">
          {{ 'delete-button-with-confirmation.component.conferma' | translate }}
        </button>
      </div>
    </ng-template>
  `,
    imports: [MatIconButton, MatTooltip, MatIcon, MatDialogTitle, MatDialogActions, MatButton, MatDialogClose, TranslateModule]
})
export class DeleteButtonWithConfirmationComponent implements OnDestroy {
  private dialog = inject(MatDialog);

  readonly templateRef = input.required<TemplateRef<any>>();

  @Input() message!: string;

  readonly tooltipText = input.required<string>();

  readonly canDelete = output<boolean>();

  readonly defaultConfirmationTpl = viewChild.required<TemplateRef<any>>('defaultConfirmation');

  subscription: Subscription = new Subscription();

  openConfirmationDialog() {
    this.subscription.add(
      this.dialog
        .open(this.templateRef() ?? this.defaultConfirmationTpl())
        .afterClosed()
        .pipe(tap(resp => this.canDelete.emit(resp)))
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
